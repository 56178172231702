import { NoticeTypeEnum } from 'lingo2-models';

export const ORIGIN_PARAM = 'o';
export const VERSION_NUMBER_PARAM = 'vn';
export const VERSION_HASH_PARAM = 'vh';

export const supportedNotices: NoticeTypeEnum[] = [
  NoticeTypeEnum.meeting_reservation,
  NoticeTypeEnum.teacher_rescheduled_meeting,
  NoticeTypeEnum.meeting_started,
  NoticeTypeEnum.my_meeting_started,
  NoticeTypeEnum.meeting_invite,
  NoticeTypeEnum.meeting_purchase_was_canceled,
  NoticeTypeEnum.meeting_participant_joined,
  NoticeTypeEnum.contract_prolong_order_unpaid,
  NoticeTypeEnum.contract_signed_up,
  NoticeTypeEnum.content_comment,
  NoticeTypeEnum.content_like,
  NoticeTypeEnum.subscriber,
  NoticeTypeEnum.content_moderation_updated,
  NoticeTypeEnum.billing_plan_was_deactivated,
  NoticeTypeEnum.billing_plan_will_be_prolong_soon,
  NoticeTypeEnum.profile_declined,
  NoticeTypeEnum.profile_verified,
  NoticeTypeEnum.classroom_was_removed,
  NoticeTypeEnum.classroom_user_leave,
  NoticeTypeEnum.meeting_complaint_reported,
  NoticeTypeEnum.meeting_complaint_resolved_teacher,
  NoticeTypeEnum.meeting_complaint_resolved_reporter,
  NoticeTypeEnum.contract_signed_up_student,
  NoticeTypeEnum.classroom_new_participant_2,
  NoticeTypeEnum.classroom_new_invitation_2,
  NoticeTypeEnum.vacation_reminder,
];
