import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Comment, IPagedResults, IPagination, Review, ReviewListItem, IOrder, IMeetingComplaint } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IThreadDigest {
  comments_count: number;
  first: Comment[];
  is_commented: boolean;
}

interface IContentReview {
  rating: number;
  comment: string;
}

interface IComplaint {
  reason_id: number;
  reason_description: string;
  comment: string;
  screenshot?: string;
}

type reviewType = 'school' | 'teacher' | 'user-service';

export type ReviewsSortOrderType = keyof ReviewListItem;

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  public constructor(private http: HttpClient) {}

  /** Поиск записей по фильтру */
  public getThreadComments(
    thread_id: string,
    filter: any,
    pagination: IPagination,
  ): Observable<IPagedResults<Comment[]>> {
    const url = `${environment.comment_url}/thread/${thread_id}`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http.get<Comment[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleCommentsResponse),
      // catchError(this.handleError),
    );
  }

  /** Загрузка дайджеста */
  public getThreadDigest(thread_id: string): Observable<IThreadDigest> {
    const url = `${environment.comment_url}/thread/${thread_id}/digest`;
    return this.http.get<IThreadDigest>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /** Получение комментария в нити */
  public getThreadComment(thread_id: string, comment_id: string): Observable<Comment> {
    const url = `${environment.comment_url}/comment/${thread_id}/${comment_id}`;
    return this.http.get<Comment>(url, { observe: 'response' }).pipe(
      map(this.handleCommentResponse),
      // catchError(this.handleError),
    );
  }

  /** Удалить комментарий */
  public remove(id: string): Observable<boolean> {
    const url = `${environment.comment_url}/comment/${id}`;
    return this.http.delete<boolean>(url, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Создать комментарий в нити */
  public createThreadComment(thread_id: string, values: Partial<Comment>): Observable<Comment> {
    const url = `${environment.comment_url}/comment/${thread_id}`;
    return this.http.post<Comment>(url, values, { observe: 'response' }).pipe(
      map(this.handleCommentResponse),
      // catchError(this.handleError),
    );
  }

  /** Поставить лайк */
  public like(id: string): Observable<boolean> {
    const url = `${environment.comment_url}/comment/${id}/like`;
    return this.http.post<boolean>(url, {}, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Убрать лайк */
  public unLike(id: string): Observable<boolean> {
    const url = `${environment.comment_url}/comment/${id}/like`;
    return this.http.delete<boolean>(url, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Оставить оценку контента после прохождения урока */
  public createContentReview(content_id, review: IContentReview): Observable<boolean> {
    const url = `${environment.comment_url}/review/content/${content_id}`;
    return this.http.post<boolean>(url, review, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Мой отзыв на митинг */
  public getMyMeetingReview(meeting_id: string): Observable<ReviewListItem> {
    const url = `${environment.comment_url}/review/meeting/${meeting_id}/my`;
    return this.http.get<ReviewListItem>(url, { observe: 'body' });
  }

  /** Список отзывов на определенную сущность(school, teacher, user-service) */
  public findReviews(
    type: reviewType,
    id: string,
    pagination?: IPagination,
    order?: IOrder<ReviewListItem>,
    commented?: boolean,
  ): Observable<IPagedResults<ReviewListItem[]>> {
    const url = `${environment.comment_url}/review/${type}/${id}`;
    let params = new HttpParams();
    if (pagination) {
      params = params.set('page', pagination.page.toString()).set('page-size', pagination.pageSize.toString());
    }
    if (order) {
      order.forEach((el) => {
        params = params.set('order', el.join(':'));
      });
    }
    if (commented) {
      params = params.set('commented', commented);
    }
    return this.http.get<ReviewListItem[]>(url, { params, observe: 'response' }).pipe(map(this.handleReviewsResponse));
  }

  public findLastReviewWithoutRating() {
    const url = `${environment.comment_url}/review/my-last-review-without-rating`;
    return this.http.get<ReviewListItem>(url);
  }

  /** Оставить оценку митингу */
  public updateReview(review: Review): Observable<boolean> {
    const url = `${environment.comment_url}/review`;
    return this.http.put<boolean>(url, review, { observe: 'response' }).pipe(map((response) => response.body as any));
  }

  /** Жалоба на пользователя */
  public createUserComplaint(user_id, complaint: IComplaint): Observable<boolean> {
    const url = `${environment.comment_url}/complaint/user`;
    return this.http.post<boolean>(url, { user_id, ...complaint }, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Жалоба на контент */
  public createContentComplaint(content_id, complaint: IComplaint): Observable<boolean> {
    const url = `${environment.comment_url}/complaint/content`;
    return this.http.post<boolean>(url, { content_id, ...complaint }, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Жалоба на слайд урока */
  public createGameSlideComplaint(game_id, content_id, slide_id, complaint: IComplaint): Observable<boolean> {
    const url = `${environment.comment_url}/complaint/game-slide`;
    return this.http.post<boolean>(url, { content_id, ...complaint }, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Жалоба на комментарий */
  public createCommentComplaint(thread_id, comment_id, complaint: IComplaint): Observable<boolean> {
    const url = `${environment.comment_url}/complaint/comment`;
    return this.http.post<boolean>(url, { thread_id, comment_id, ...complaint }, { observe: 'response' }).pipe(
      map((response) => (response.body as any).result),
      // catchError(this.handleError),
    );
  }

  /** Оставить замечание митингу */
  public createMeetingComplaint(review: Partial<IMeetingComplaint>): Observable<Partial<IMeetingComplaint>> {
    const url = `${environment.comment_url}/complaint/meeting`;
    return this.http
      .post<Partial<IMeetingComplaint>>(url, review, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  private handleCommentResponse(response: HttpResponse<Comment>): Comment {
    return new Comment(response.body);
  }

  private handleCommentsResponse(response: HttpResponse<Comment[]>): IPagedResults<Comment[]> {
    // const totalRecords = +res.headers.get('X-InlineCount');
    return {
      results: response.body.map((c) => new Comment(c)),
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }

  private handleReviewsResponse(response: HttpResponse<ReviewListItem[]>): IPagedResults<ReviewListItem[]> {
    const pagination: IPagination = {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
    return {
      results: response.body,
      pagination,
    };
  }
}
