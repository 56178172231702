import { IContentBlock } from '@core/services/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { Meeting, IFindMeetingsFilter, MeetingDetailsType } from 'lingo2-models';

const DEFAULT_DETAILS: MeetingDetailsType[] = [
  'id',
  'slug',
  'type',
  'title',
  'subject',
  'level',
  'language',
  'author:sm',
  'author_id',
  'cover',
  'cover_id',
  'price',
  'description',
  'keywords',
  'visit_info',
  'can',
  'is',
  'has',
  'begin',
  'begin_at',
  'end_at',
  'duration',
  'created_at',
  'published_at',
  'finished_at',
  'options',
  'status',
  'user_service_contract',
  // ? 'user_service',
  // 'archived_at',
  // 'started_at',
  // 'finished_at',
  // 'access',
  // 'charging',
  // 'participants_limit',
  // 'participants_count',
  // 'reservations_count',
  // 'status',
  // 'classroom:md',
];

export type MeetingsBlock = IContentBlock<Meeting, IFindMeetingsFilter>;

import * as MeetingsAction from './meetings.actions';

export interface MeetingsState extends MeetingsBlock {
  error: string;
  details: MeetingDetailsType[];
  infiniteScroll: boolean;
}

const PAGE_SIZE = 100;

const initialState: MeetingsState = {
  items: [],
  loading: false,
  loaded: false,
  filter: {
    content_type: [],
  },
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  },
  error: null,
  details: DEFAULT_DETAILS,
  infiniteScroll: true,
};

const contentReducer = createReducer(
  initialState,

  on(MeetingsAction.requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  on(MeetingsAction.loadClassroomMeetings, (state) => ({ ...state, loading: true, error: null })),

  on(MeetingsAction.loadClassroomMeetingsSuccess, (state, { response }) => {
    const uniqueItems = response.results.filter((x) => !state.items.some((item) => item.id === x.id));
    return {
      ...state,
      items: state.infiniteScroll ? [...state.items, ...uniqueItems] : [...uniqueItems],
      pagination: {
        ...state.pagination,
        total: response.total,
        totalPages: response.totalPages,
        page: response.page,
      },
      loading: false,
      loaded: true,
    };
  }),

  on(MeetingsAction.clearClassroomMeetings, (state) => ({ ...initialState })),

  on(MeetingsAction.loadNextPageClassroomMeetings, (state) => {
    const isAvailable = state.pagination.page < state.pagination.totalPages;
    const page = isAvailable ? state.pagination.page + 1 : state.pagination.page;
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
  }),

  on(MeetingsAction.pushClassroomMeeting, (state, { meeting }) => {
    const isUnique = !state.items.some((item) => item.id === meeting.id);
    return {
      ...state,
      items: isUnique ? [meeting, ...state.items] : [...state.items],
    };
  }),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
