import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@core/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { BaseNotificationComponent } from './base-notification/base-notification.component';
import { NotificationComponent } from './notification.component';
import { BillingPlanWasDeactivatedComponent } from './notifications/billing-plan-was-deactivated/billing-plan-was-deactivated.component';
import { BillingPlanWillBeProlongSoonComponent } from './notifications/billing-plan-will-be-prolong-soon/billing-plan-will-be-prolong-soon.component';
import { ClassroomLeaveComponent } from './notifications/classroom-leave/classroom-leave.component';
import { ClassroomNewInvitation2Component } from './notifications/classroom-new-invitation-2/classroom-new-invitation-2.component';
import { ClassroomNewParticipant2Component } from './notifications/classroom-new-participant-2/classroom-new-participant-2.component';
import { ClassroomRemovedComponent } from './notifications/classroom-removed/classroom-removed.component';
import { ContentCommentComponent } from './notifications/content-comment/content-comment.component';
import { ContentLikeComponent } from './notifications/content-like/content-like.component';
import { ContractProlongOrderUnpaidComponent } from './notifications/contract-prolong-order-unpaid/contract-prolong-order-unpaid.component';
import { ContractSignedUpComponent } from './notifications/contract-signed-up/contract-signed-up.component';
import { ContractSignedUpStudentComponent } from './notifications/contract-signed-up-student/contract-signed-up-student.component';
import { MeetingComplaintReportedComponent } from './notifications/meeting-complaint-reported/meeting-complaint-reported.component';
import { MeetingComplaintResolvedReporterComponent } from './notifications/meeting-complaint-resolved-reporter/meeting-complaint-resolved-reporter.component';
import { MeetingComplaintResolvedTeacherComponent } from './notifications/meeting-complaint-resolved-teacher/meeting-complaint-resolved-teacher.component';
import { MeetingInviteComponent } from './notifications/meeting-invite/meeting-invite.component';
import { MeetingParticipantJoinedComponent } from './notifications/meeting-participant-joined/meeting-participant-joined.component';
import { MeetingPurchaseCanceledComponent } from './notifications/meeting-purchase-canceled/meeting-purchase-canceled.component';
import { MeetingReservationComponent } from './notifications/meeting-reservation/meeting-reservation.component';
import { MeetingStartedComponent } from './notifications/meeting-started/meeting-started.component';
import { ModerationUpdateComponent } from './notifications/moderation-update/moderation-update.component';
import { MyMeetingStartedComponent } from './notifications/my-meeting-started/my-meeting-started.component';
import { ProfileDeclinedComponent } from './notifications/profile-declined/profile-declined.component';
import { ProfileVerifiedComponent } from './notifications/profile-verified/profile-verified.component';
import { SubscriberComponent } from './notifications/subscriber/subscriber.component';
import { TeacherRescheduledMeetingComponent } from './notifications/teacher-rescheduled-meeting/teacher-rescheduled-meeting.component';
import { VacationReminderComponent } from './notifications/vacation-reminder/vacation-reminder.component';
import { RelativeUrlPipe } from './pipes/relative-url.pipe';

@NgModule({
  declarations: [
    BaseNotificationComponent,
    BillingPlanWasDeactivatedComponent,
    BillingPlanWillBeProlongSoonComponent,
    ClassroomNewInvitation2Component,
    ClassroomLeaveComponent,
    ClassroomNewParticipant2Component,
    ClassroomRemovedComponent,
    ContentCommentComponent,
    ContentLikeComponent,
    ContractProlongOrderUnpaidComponent,
    ContractSignedUpComponent,
    ContractSignedUpStudentComponent,
    MeetingComplaintReportedComponent,
    MeetingComplaintResolvedReporterComponent,
    MeetingComplaintResolvedTeacherComponent,
    MeetingInviteComponent,
    MeetingParticipantJoinedComponent,
    MeetingPurchaseCanceledComponent,
    MeetingReservationComponent,
    MeetingStartedComponent,
    ModerationUpdateComponent,
    MyMeetingStartedComponent,
    NotificationComponent,
    ProfileDeclinedComponent,
    ProfileVerifiedComponent,
    RelativeUrlPipe,
    SubscriberComponent,
    TeacherRescheduledMeetingComponent,
    VacationReminderComponent,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, OnUiButtonModule, DirectivesModule],
  exports: [NotificationComponent],
})
export class NotificationModule {}
