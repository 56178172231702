import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AlertModule } from '@core/components/alert/alert.module';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs';
import { AccountService, ContextService, IconsService, PlatformService } from '../../services';

@Component({
  selector: 'app-timezone-notifier',
  standalone: true,
  imports: [CommonModule, OnUiButtonModule, TranslateModule, AlertModule],
  templateUrl: './timezone-notifier.component.html',
  styleUrls: ['./timezone-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimezoneNotifierComponent extends ChangableComponent implements OnInit {
  public alertVisible = false;
  public myTimezone: string;
  public svgsetIcon = IconsService.svgsetIconUrl;

  public constructor(
    private contextService: ContextService,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.onBrowserOnly(() => {
      this.contextService.me$
        .pipe(
          map((me) => me?.timezone),
          distinctUntilChanged(),
          tap((timezone) => {
            this.myTimezone = timezone;
            this.checkTimezone();
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    });
  }

  public save() {
    this.accountService
      .updateAccount({ timezone: this.browserTimezone })
      .pipe(
        tap(() => {
          localStorage.setItem('timezone-skip', this.browserTimezone);
          window.location.reload();
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public skip() {
    localStorage.setItem('timezone-skip', this.browserTimezone);
    this.alertVisible = false;
    this.detectChanges();
  }

  public get browserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get browserTimezoneOffset() {
    return this.timezoneOffsetToHours(-new Date().getTimezoneOffset());
  }

  public get timezoneSkip() {
    return localStorage.getItem('timezone-skip');
  }

  protected checkTimezone() {
    if (!!this.myTimezone) {
      this.alertVisible = this.myTimezone !== this.browserTimezone && this.browserTimezone !== this.timezoneSkip;
      this.detectChanges();
    }
  }

  /**
   * @sample timezoneOffsetToHours(-4200) -> 'UTC-07:00'
   * @sample timezoneOffsetToHours(4200) -> 'UTC+07:00'
   */
  protected timezoneOffsetToHours(offset: number): string {
    const sign = offset < 0 ? '-' : '+';
    const hhmm = new Date(Math.abs(offset) * 60 * 1000).toISOString().substr(11, 5);
    return 'UTC' + sign + hhmm;
  }
}
