import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createPagedResultsFromResponse } from '@core/helpers/request';
import { environment } from '@env/environment';
import {
  BreakUserServiceContractRequest,
  CancelUserServiceContractRequest,
  CreateUserServiceContractRequest,
  CreateUserServiceContractResponse,
  FindUserServiceContractFilters,
  IPagedResults,
  IPagination,
  IStatusResponse,
  UserServiceContract,
  SignupUserServiceContractRequest,
  SignupUserServiceContractResponse,
  ScheduleUserServiceContractRequest,
  UserServiceContractOrder,
  UserServiceContractOrderCheckoutResponse,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type UserServiceContractDetailsType = keyof UserServiceContract;

@Injectable({
  providedIn: 'root',
})
export class UserServiceContractsService {
  public constructor(private http: HttpClient) {}

  /**
   * Найти контракт по id
   */
  public findOne(id: string, details?: UserServiceContractDetailsType[]): Observable<UserServiceContract> {
    const url = `${environment.content_url}/contracts/${id}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http
      .get<UserServiceContract>(url, { params, observe: 'body' })
      .pipe(map((response) => new UserServiceContract(response)));
  }

  /**
   * Найти контракты по фильтру
   */
  public find(
    filter: FindUserServiceContractFilters,
    pagination: IPagination,
    details?: UserServiceContractDetailsType[],
  ): Observable<IPagedResults<UserServiceContract[]>> {
    const url = `${environment.content_url}/contracts`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http
      .get<UserServiceContract[]>(url, { params, observe: 'response' })
      .pipe(map(this.handleUserServiceContractsResponse.bind(this)));
  }

  /**
   * Запланировать время для контракт для услуги
   */
  public schedule(request: ScheduleUserServiceContractRequest): Observable<CreateUserServiceContractResponse> {
    const url = `${environment.content_url}/contracts/schedule`;
    return this.http
      .post<CreateUserServiceContractResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new CreateUserServiceContractResponse(response)));
  }

  /**
   * Подготовить контракт на услугу для ознакомления
   */
  public previewContract(request: ScheduleUserServiceContractRequest): Observable<CreateUserServiceContractResponse> {
    const url = `${environment.content_url}/contracts/preview`;
    return this.http
      .post<CreateUserServiceContractResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new CreateUserServiceContractResponse(response)));
  }

  /**
   * Создать контракт для услуги и записать участника
   */
  public createAndSignup(request: CreateUserServiceContractRequest): Observable<SignupUserServiceContractResponse> {
    const url = `${environment.content_url}/contracts/signup`;
    return this.http
      .post<SignupUserServiceContractResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new SignupUserServiceContractResponse(response)));
  }

  /** Записать пользователя в контракт для услуги */
  public signup(request: SignupUserServiceContractRequest): Observable<SignupUserServiceContractResponse> {
    const url = `${environment.content_url}/contracts/signup`;
    return this.http
      .put<SignupUserServiceContractResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new SignupUserServiceContractResponse(response)));
  }

  /** Отменить контракт для услуги */
  public cancelContract(request: CancelUserServiceContractRequest): Observable<IStatusResponse> {
    const url = `${environment.content_url}/contracts/cancel`;
    return this.http.post<IStatusResponse>(url, request, { observe: 'body' });
  }

  /** Прервать контракт для услуги */
  public breakContract(request: BreakUserServiceContractRequest): Observable<IStatusResponse> {
    const url = `${environment.content_url}/contracts/break`;
    return this.http.post<IStatusResponse>(url, request, { observe: 'body' });
  }

  /** Один заказ на контракт по ID */
  public findOrderById(order_id: string): Observable<UserServiceContractOrder> {
    const url = `${environment.content_url}/contracts/orders/${order_id}`;
    return this.http
      .get<UserServiceContractOrder>(url, { observe: 'body' })
      .pipe(map((response) => new UserServiceContractOrder(response)));
  }

  /** Информация для оплаты контракта на услугу */
  public getOrderCheckoutDetails(order_id: string): Observable<UserServiceContractOrderCheckoutResponse> {
    const url = `${environment.content_url}/contracts/orders/${order_id}/checkout`;
    return this.http
      .post<UserServiceContractOrderCheckoutResponse>(url, {}, { observe: 'body' })
      .pipe(map((response) => new UserServiceContractOrderCheckoutResponse(response)));
  }

  private handleUserServiceContractsResponse(
    response: HttpResponse<UserServiceContract[]>,
  ): IPagedResults<UserServiceContract[]> {
    return createPagedResultsFromResponse(response, (values) => new UserServiceContract(values));
  }
}
