import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  AuthService,
  ConfigService,
  ContentService,
  FeaturesService,
  IconsService,
  LanguageService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CLibraryFilterData } from '@store/models';
import { getLibraryRouteData, KEY_SUBJECT_CODES_FOR_SAVE } from '@store/reducers/library.reducer';
import { getMe, getMyProfile } from '@store/reducers/profile.reducer';
import {
  EContentPanelRoute,
  EntityTypeEnum,
  Language,
  nativeSpokenLevel,
  Subject as BaseSubject,
  SubjectCategoryEnum,
  SubjectsRegistry,
  UserProfile,
} from 'lingo2-models';
import { uniqBy } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';

type subjectType = 'foreign' | 'native' | 'others';
type tabsType = 'all' | 'favorites';

@Component({
  selector: 'app-subject-selector',
  templateUrl: './subject-selector.component.html',
  styleUrls: ['./subject-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubjectSelectorComponent extends ChangableComponent implements OnInit, OnDestroy {
  public svgsetIcon = IconsService.svgsetIconUrl;
  public defaultPlaceholder = this.translateService.instant('subject-selector-in-header.default-placeholder');
  public focus = false;
  public contentDisplay = false;
  public tabsActive: tabsType = 'all';
  public displayCategory: subjectType = null;
  public searchList: BaseSubject[] = [];
  public recentList: BaseSubject[] = [];
  public searchTerm = '';
  public backLabel = '';
  public showSelector = false;
  public isTeacher: boolean;
  public selectedSubjects: BaseSubject[] = [];

  private _subjectsRegistry: SubjectsRegistry;
  private _libraryRouterData: CLibraryFilterData;
  private _currentLanguage: Language;
  private _favoritedSubjects: BaseSubject[] = [];
  private _userProfile: UserProfile;
  private search$ = this.register(new Subject<boolean>());
  public isStudent = AccountService.isStudent;
  private nativeSubjectsArray: BaseSubject[];

  private _libraryRouter = new LibraryRouter();

  constructor(
    protected contentService: ContentService,
    protected configService: ConfigService,
    protected languageService: LanguageService,
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected store: Store,
    protected router: Router,
    public deviceService: DeviceDetectorService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    combineLatest([
      this.configService.subjectsV2$,
      this.store.select(getLibraryRouteData).pipe(filter((v) => !!v)),
      this.languageService.language$,
      this.store.select(getMyProfile).pipe(filter((v) => !!v)),
      this.store.select(getMe),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([subjectsRegistry, libraryRouterData, language, profile, me]) => {
        this._subjectsRegistry = subjectsRegistry;
        this._libraryRouterData = libraryRouterData;
        this.showSelector = this.setShowSelector();
        this._currentLanguage = language;
        this._userProfile = profile;
        this.nativeSubjectsArray = this.getNativeSubjects();
        this.isTeacher = AccountService.isAsIfTeacher(me);
        this.selectedSubjects = this.findSubjectsByCode(libraryRouterData?.subjectCodes || []);
        this.loadFavoritedSubjects();
        if (this.isBrowser) {
          this.recentList = JSON.parse(localStorage.getItem('resentSubjects')) || [];
        }
        this.detectChanges();
      });

    this.search$
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.filterSubjectsByTerm());
  }

  private setShowSelector(): boolean {
    const isExclude = ['/classes/join', '/constructor', '/content/collections'].find((r) =>
      this._libraryRouterData?.url.includes(r),
    );
    if (isExclude) {
      return false;
    }
    return [
      EContentPanelRoute.main,
      EContentPanelRoute.lessons,
      EContentPanelRoute.tutors,
      EContentPanelRoute.classes,
      EContentPanelRoute.schools,
      EContentPanelRoute.collections,
    ].includes(this._libraryRouterData?.baseRoute);
  }

  public isNativeSubject(subject: BaseSubject): boolean {
    return subject.category_id === SubjectCategoryEnum.native_language;
  }

  public isForeignSubject(subject: BaseSubject): boolean {
    return subject.category_id === SubjectCategoryEnum.foreign_language;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public filteredList() {
    // TODO fix stats: null
    // if (!this.isTeacher)
    if (false) {
      switch (this.displayCategory) {
        case 'foreign':
          return this._subjectsRegistry?.foreign.filter((subject) => subject?.stats?.content_count > 0);
        case 'native':
          return this.nativeSubjectsArray;
        case 'others':
          return this._subjectsRegistry?.non_categorized.filter((subject) => subject?.stats?.content_count > 0);
        default:
          return null;
      }
    } else {
      switch (this.displayCategory) {
        case 'foreign':
          return this._subjectsRegistry?.foreign;
        case 'native':
          return this.nativeSubjectsArray;
        case 'others':
          return this._subjectsRegistry?.non_categorized;
        default:
          return null;
      }
    }
  }

  public favoritedSubjects(): BaseSubject[] {
    return this._favoritedSubjects;
  }

  public get placeholder(): string {
    if (this.selectedSubjects.length === 1) {
      const [last, ...others] = this.selectedSubjects;
      return `${last.title}`;
    }
    if (this.selectedSubjects.length > 1) {
      const [last, ...others] = this.selectedSubjects;
      return `${last.title} +${others.length}`;
    }
    return this.defaultPlaceholder;
  }

  public isFavorite(id: number): boolean {
    return !!this._favoritedSubjects.find((_fs) => +_fs.id === +id);
  }

  public onCloseContent() {
    this.contentDisplay = false;
    this.searchTerm = '';
    this.detectChanges();
  }

  public onFocus() {
    this.focus = true;
    this.contentDisplay = true;
  }

  public onBlur() {
    this.focus = false;
  }

  public trackByFn(index, subject: BaseSubject): number {
    return subject?.id;
  }

  public onMakeFavorite(event: MouseEvent, subject: BaseSubject) {
    event.stopPropagation();
    event.cancelBubble = true;

    this.contentService
      .setBookmark(subject.id.toString(), !this.isFavorite(subject.id), EntityTypeEnum.subject)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.loadFavoritedSubjects());
  }

  public onSelectSubject(subject: BaseSubject) {
    this._libraryRouter.setBaseRoute(this._libraryRouterData?.baseRoute || EContentPanelRoute.main);
    this._libraryRouter.setSubject(subject.code);
    this._libraryRouter.setContentTypes(this._libraryRouterData?.contentTypeCodes);
    this.contentDisplay = false;
    this.displayCategory = null;
    this.tabsActive = 'all';
    this.onApplyFilter();
    this.clearSearch();
    if (this.isBrowser) {
      localStorage.setItem('resentSubjects', JSON.stringify(uniqBy([subject, ...this.recentList], 'id').slice(0, 3)));
    }
    this.detectChanges();
  }

  public onApplyFilter() {
    const link = this._libraryRouter.getLink();
    this.router.navigate([link.path], { queryParams: link.parameters }).then();
  }

  public onOpenCategory(category: subjectType) {
    // logger.log('SubjectSelectorComponent::onOpenCategory', category);
    this.displayCategory = category;
    this.backLabel = `subject-selector-in-header.category.${category}`;
    this.detectChanges();
  }

  public onSearchSubjects() {
    this.search$.next(true);
  }

  public onClearSubject(event) {
    event.preventDefault();
    /**
     * Сброс сохраненной дисциплины, если пользователь сбросил в селекторе
     * Задача: https://app.clickup.com/t/85zrxkvjv
     */
    if (this.isBrowser) {
      localStorage.removeItem(KEY_SUBJECT_CODES_FOR_SAVE);
    }
    /**
     * Конец костыля
     */
    this._libraryRouter.setBaseRoute(this._libraryRouterData?.baseRoute || EContentPanelRoute.main);
    this._libraryRouter.setSubjects([]);
    this.clearSearch();
    const link = this._libraryRouter.getLink();

    /**
     * Так как на странице main не используется дисциплина в построении URL
     * то сброс селектора не вызывает перезагрузку с очисткой дисциплины
     * поэтому только для main страницы происходит релоад таким путем
     *
     * Для задачи: https://app.clickup.com/t/85zt6daxc
     */
    if (link.path === '/' + EContentPanelRoute.main) {
      this.router
        .navigateByUrl('/clear', { skipLocationChange: true })
        .then(() => this.router.navigate([link.path], { queryParams: link.parameters }).then());
    } else {
      this.router.navigate([link.path], { queryParams: link.parameters });
    }
  }

  private filterSubjectsByTerm() {
    const filtered: BaseSubject[] = [];
    const { user, foreign, native, non_categorized, recent, ...rest } = this._subjectsRegistry;

    if (!!this.searchTerm) {
      uniqBy(
        [
          ...user,
          // ...foreign,
          ...native,
          ...non_categorized,
          ...recent,
        ],
        'id',
      ).map((_item) => {
        if (_item.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0) {
          filtered.push(_item);
        }
      });
    }

    this.searchList = filtered;
    this.detectChanges();
  }

  private clearSearch() {
    this.searchTerm = '';
    this.searchList = [];
  }

  private findSubjectsByCode(subjectsCodes: string[]): BaseSubject[] {
    return uniqBy(
      [
        // ...this._subjectsRegistry.other,
        ...this._subjectsRegistry.user,
        ...this._subjectsRegistry.native,
        ...this._subjectsRegistry.foreign,
        ...this._subjectsRegistry.non_categorized,
        ...this._subjectsRegistry.recent,
      ].filter((_s) => !!subjectsCodes.find((_sc) => _sc === _s.code)),
      'id',
    );
  }

  private findSubjectsById(subjectsIds: number[]): BaseSubject[] {
    return uniqBy(
      [
        // ...this._subjectsRegistry.other,
        ...this._subjectsRegistry.user,
        ...this._subjectsRegistry.native,
        ...this._subjectsRegistry.foreign,
        ...this._subjectsRegistry.non_categorized,
        ...this._subjectsRegistry.recent,
      ].filter((_s) => !!subjectsIds.find((_sc) => +_sc === +_s.id)),
      'id',
    );
  }

  private findSubjectsByLanguagesId(languagesIds: number[]): BaseSubject[] {
    return uniqBy(
      [
        // ...this._subjectsRegistry.other,
        ...this._subjectsRegistry.user,
        ...this._subjectsRegistry.native,
        // ...this._subjectsRegistry.foreign,
        ...this._subjectsRegistry.non_categorized,
        // ...this._subjectsRegistry.recent
      ].filter((_s) => !!languagesIds.find((_li) => +_li === +_s.language_id)),
      'id',
    );
  }

  private loadFavoritedSubjects() {
    this.configService
      .getSubjects(this._currentLanguage.code, false, ['visit_info'])
      .pipe(take(1))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((favorites) => {
        this._favoritedSubjects = favorites;
        this.detectChanges();
      });
  }

  private getNativeSubjects(): BaseSubject[] {
    const nativeLanguagesIds = this._userProfile?.spoken_languages
      .filter((_nl) => +_nl.level_id === nativeSpokenLevel)
      .map((_l) => _l.language_id);
    let spokenLanguagesSubjects: BaseSubject[];
    if (nativeLanguagesIds.length > 0) {
      spokenLanguagesSubjects = this.findSubjectsByLanguagesId(nativeLanguagesIds);
    } else {
      spokenLanguagesSubjects = this.findSubjectsByLanguagesId([this._currentLanguage.id]);
    }
    // https://app.clickup.com/t/1yyetq5
    const teachingSubjects = []; // this.findSubjectsById(teachingSubjectsIds).filter((_s) => !!_s.language_id);
    return uniqBy([...spokenLanguagesSubjects, ...teachingSubjects], 'id');
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }
}
