export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-web',
    versionDate: '2024-09-11T19:31:12.292Z',
    gitCommitHash: '01d6b4a',
    versionLong: '35.0.0-01d6b4a',
};
export default versions;
