<div class="no-content-stub" [ngClass]="position">
  <svg viewBox="0 0 64 64" width="64" height="64" fill="#D2D8E1">
    <use [attr.xlink:href]="svgSetIcon(icon)"></use>
  </svg>
  <p class="stub-text" [innerHTML]="text | translate"></p>
  <ng-container *ngIf="text2">
    <p class="stub-text stub-text-2" [innerHTML]="text2 | translate"></p>
  </ng-container>
  <ng-container *ngIf="link">
    <p class="stub-text stub-link"  (click)="onLinkClick()" [innerHTML]="link | translate"></p>
  </ng-container>
</div>
