import { Injectable } from '@angular/core';
import { ContextService } from '@core/services/context.service';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { AnyType, FeatureEnum, FeatureStatusEnum, IUserFeature, TenantEnum, User } from 'lingo2-models';
import { isString } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** @deprecated */
type FeatureStateType = 'on' | 'off' | 'test' | 'dev';

/** @deprecated */
interface IFeature {
  websockets: FeatureStateType; // в принципе - вебсокеты
  'profile.settings': FeatureStateType; // настройки пользователя [/me/settings]
  'profile.finance': FeatureStateType; // баланс пользователя [/me/finance]
  'finance.referrals': FeatureStateType; // реферальная система
  'finance.payouts': FeatureStateType; // выплата [/me/finance/payouts]
  'finance.plans': FeatureStateType; // подписки
  'finance.coupons': FeatureStateType; // купоны
  'user-services': FeatureStateType; // услуги преподавателя [/me/teacher/services]
  'free-classes': FeatureStateType; // бесплатные классы
  'fee-classes': FeatureStateType; // платные классы
  'credits-classes': FeatureStateType; // классы за кредиты
  'public-classes': FeatureStateType; // публичные классы
  notifications: FeatureStateType; // нотификации
  chat: FeatureStateType; // чат
  'mobile-app': FeatureStateType; // мобильное приложение
  'onboarding.trackers': FeatureStateType; // трекеры активности преподавателя
  classrooms: FeatureStateType; // учебные комнаты
  collections: FeatureStateType; // коллекции
  courses: FeatureStateType; // курсы
  'teaching-video-webcam': FeatureStateType; // запись видео с веб-камеры в профиле
  'favorites-page': FeatureStateType; // страница Избранное
  'schedule.vacation': FeatureStateType; // режим отпуска
  vocabulary: FeatureStateType; // словарный запас
  'vocabulary.lesson': FeatureStateType; // создать урок из слов
  httpErrors: FeatureStateType; // Показывать уведомление об HTTP ошибках
  billing_2: FeatureStateType; // 2ая версия API для биллинга
}

/** фиксированный список фич для домена onclass.me.* */
const onclassMeFeatures: IUserFeature[] = [
  {
    feature: FeatureEnum.draft_meetings,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.billing_v2,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.credits_meetings,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.finance_plans,
    status: FeatureStatusEnum.enabled,
  },
];

const onclassComFeatures: IUserFeature[] = [
  {
    feature: FeatureEnum.main,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.pins,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.following,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_step_services,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_step_calendar,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_step_app,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.nearest_meet_widget,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_classes_homework,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_classes_schools,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_classes_tutors,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_classes_students,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_library_services,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.my_library_collections,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.statistics,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.safe_transaction,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_services,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_lessons,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_collections,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_schools,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_publications,
    status: FeatureStatusEnum.enabled,
  },
  {
    feature: FeatureEnum.profile_reviews,
    status: FeatureStatusEnum.enabled,
  },
];

const LOCAL_STORAGE_TENANT_OVERRIDE_KEY = 'tenant';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService extends DestroyableComponent {
  public tenant$ = new BehaviorSubject<TenantEnum>(null);
  public tenantOverride$ = new BehaviorSubject<TenantEnum>(null);
  private _tenant: TenantEnum;

  public isConnected$ = new BehaviorSubject<boolean>(false);

  /** @deprecated */
  protected _me: User;

  /** @deprecated */
  protected static isLimited: boolean;

  public constructor(protected context: ContextService, protected store: Store, protected platform: PlatformService) {
    super(platform);
    this.init();
  }

  public resolveTenant(): TenantEnum {
    const hostname = window.location.hostname;

    if (/^(.+\.)?onclass\.me$/.test(hostname)) {
      return TenantEnum.onclass_me;
    }

    return TenantEnum.onclass_com;
  }

  public get tenant(): TenantEnum {
    if (this.tenantOverride) {
      return this.tenantOverride;
    }
    if (!this._tenant) {
      this._tenant = this.resolveTenant();
    }

    return this._tenant;
  }

  public set tenant(value: TenantEnum) {
    this._tenant = value;
    this.tenant$.next(this.tenant);

    FeaturesService.isLimited = this.tenant === TenantEnum.onclass_me;
  }

  public overrideTenant(value: TenantEnum) {
    this.tenantOverride = value;
    this.tenantOverride$.next(this.tenantOverride);
    this.tenant$.next(this.tenant);

    FeaturesService.isLimited = this.tenant === TenantEnum.onclass_me;
  }

  /**
   * Фича доступна пользователю
   *
   * Интерфейс IFeature сохранён для совместимости с lingo2_forms
   * */
  public isAvailable(feature: FeatureEnum | keyof IFeature, user?: User): boolean {
    const _feature = isString(feature) ? this.convertOldFeature(feature as AnyType) : (feature as FeatureEnum);

    if (!user) {
      user = this._me;
    }

    const userFeature = this.getUserFeature(user, _feature);
    return userFeature?.status === FeatureStatusEnum.enabled;
  }

  /**
   * @deprecated
   *
   * @use FeaturesService.tenant$
   * @todo FeaturesService.uiRules$
   */
  public static get isLimitedVersion() {
    return FeaturesService.isLimited;
  }

  protected getUserFeature(user: User, feature: FeatureEnum): IUserFeature {
    switch (this.tenant) {
      case TenantEnum.onclass_me:
        return onclassMeFeatures.find((f) => f.feature === feature);

      case TenantEnum.onclass_com:
        const features = [...onclassComFeatures, ...(user?.features || [])];
        return (features || []).find((f) => f.feature === feature);

      default:
        return null;
    }
  }

  protected init() {
    this.tenantOverride$.next(this.tenantOverride);
    this.tenant$.next(this.tenant);

    FeaturesService.isLimited = this.tenant === TenantEnum.onclass_me;

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((me) => {
        this._me = me;
      });
  }

  protected convertOldFeature(feature: keyof IFeature): FeatureEnum {
    return {
      websockets: null,
      'profile.settings': FeatureEnum.profile_settings,
      'profile.finance': FeatureEnum.profile_finance,
      'finance.referrals': FeatureEnum.finance_referrals,
      'finance.payouts': FeatureEnum.finance_payouts,
      'finance.plans': FeatureEnum.finance_plans,
      'finance.coupons': FeatureEnum.finance_coupons,
      'user-services': FeatureEnum.user_services,
      'free-classes': FeatureEnum.free_meetings,
      'fee-classes': FeatureEnum.safe_deal_meetings,
      'credits-classes': FeatureEnum.credits_meetings,
      'public-classes': null,
      notifications: FeatureEnum.notifications,
      chat: FeatureEnum.chat,
      'mobile-app': FeatureEnum.mobile_apps,
      'onboarding.trackers': FeatureEnum.onboarding_trackers,
      classrooms: FeatureEnum.classrooms,
      collections: FeatureEnum.collections,
      courses: FeatureEnum.courses,
      'teaching-video-webcam': FeatureEnum.teaching_video_webcam,
      'favorites-page': FeatureEnum.favorites_page,
      'schedule.vacation': FeatureEnum.schedule_vacation,
      vocabulary: FeatureEnum.vocabulary,
      'vocabulary.lesson': FeatureEnum.vocabulary_lesson,
      httpErrors: FeatureEnum.httpErrors,
      billing_2: FeatureEnum.billing_v2,
    }[feature];
  }

  private get tenantOverride(): TenantEnum {
    return (this.isBrowser ? localStorage.getItem(LOCAL_STORAGE_TENANT_OVERRIDE_KEY) || null : null) as TenantEnum;
  }

  private set tenantOverride(value: TenantEnum) {
    if (this.isBrowser) {
      if (value) {
        localStorage.setItem(LOCAL_STORAGE_TENANT_OVERRIDE_KEY, value);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_TENANT_OVERRIDE_KEY);
      }
    }
  }
}
