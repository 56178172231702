import { Injectable, Injector } from '@angular/core';
import {
  FindAccountStrategyEnum,
  FindUserServiceStrategyEnum,
  IFindAccountsFilter,
  IFindMeetingsFilter,
  IFindUserServicesFilter,
  IPagination,
  Meeting,
  MeetingDetailsType,
  User,
  UserService,
  UserServiceContract,
  UserServiceDetailsType,
  FindUserServiceContractsById,
  IPagedResults,
} from 'lingo2-models';
import { uniq, chunk } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountDetailsType, AccountService } from './lingo2-account/account.service';
import { MeetingsService } from './lingo2-content/meetings.service';
import {
  UserServiceContractDetailsType,
  UserServiceContractsService,
} from './lingo2-content/user-service-contracts.service';
import { UserServicesService } from './lingo2-content/user-services.service';

export type ExtendExtendableType = 'account' | 'meeting' | 'user_service';
export interface ExtendOptionsType {
  account?: { details: AccountDetailsType[] };
  meeting?: { details: MeetingDetailsType[] };
  user_service?: { details: UserServiceDetailsType[] };
  user_service_contract?: { details: UserServiceContractDetailsType[] };
}

export interface IExtendableEntity {
  account_id?: string;
  meeting_id?: string;
  user_service_id?: string;
  user_service_contract_id?: string;
}

export type ExtendableEntityType = Partial<IExtendableEntity>;

export interface IExtendEntity extends IExtendableEntity {
  account?: User;
  meeting?: Meeting;
  user_service?: UserService;
  user_service_contract?: UserServiceContract;
}

export type ExtendedEntityType = Partial<IExtendEntity>;

export interface Extensions {
  accounts?: User[];
  meetings?: Meeting[];
  user_services?: UserService[];
  user_service_contracts?: UserServiceContract[];
}

/**
 * Сервис для дополнения сущностей другими связанными сущностями
 */
@Injectable({
  providedIn: 'root',
})
export class EntityExtenderService {
  protected accountDetails: AccountDetailsType[] = ['id', 'slug', 'first_name', 'last_name'];
  protected meetingDetails: MeetingDetailsType[] = [
    'id',
    'title',
    'slug',
    'type',
    'subject',
    'participants_limit',
    'participants_count',
    'classroom',
    'can',
    'is',
  ];
  protected userServiceDetails: UserServiceDetailsType[] = ['id', 'title', 'options', 'participants_limit'];
  protected userServiceContractDetails: UserServiceContractDetailsType[] = [
    'id',
    'user_service_terms',
    // TODO 'is'
    // TODO 'can'
  ];

  protected accountService: AccountService;
  protected meetingsService: MeetingsService;
  protected userServicesService: UserServicesService;
  protected userServiceContractsService: UserServiceContractsService;

  public constructor(protected injector: Injector) {
    this.accountService = injector.get(AccountService);
    this.meetingsService = injector.get(MeetingsService);
    this.userServicesService = injector.get(UserServicesService);
    this.userServiceContractsService = injector.get(UserServiceContractsService);
    // TODO ngrx (store)
  }

  public extend$(
    items: ExtendableEntityType[],
    details: ExtendExtendableType[] = [],
    options?: ExtendOptionsType,
  ): Observable<ExtendedEntityType[]> {
    return zip(
      this.findUserServiceContracts$(items, options),
      details.includes('account') ? this.findAccounts$(items, options) : of([]),
      details.includes('meeting') ? this.findMeetings$(items, options) : of([]),
      details.includes('user_service') ? this.findUserServices$(items, options) : of([]),
    ).pipe(
      map(([user_service_contracts, accounts, meetings, user_services]) =>
        items.map((item) => this.mapper(item, { user_service_contracts, accounts, meetings, user_services })),
      ),
    );
  }

  protected mapper(item: ExtendableEntityType, extensions: Extensions): ExtendedEntityType {
    const { user_service_contracts, accounts, meetings, user_services } = extensions;
    const result = item as ExtendedEntityType;
    if (item.user_service_contract_id) {
      result.user_service_contract = user_service_contracts.find((v) => v.id === item.user_service_contract_id);
      result.user_service = result.user_service_contract?.user_service;
    } else {
      if (item.user_service_id) {
        result.user_service = user_services.find((v) => v.id === item.user_service_id);
      }
    }
    if (item.account_id) {
      result.account = accounts.find((v) => v.id === item.account_id);
    }
    if (item.meeting_id) {
      result.meeting = meetings.find((v) => v.id === item.meeting_id);
    }
    return result;
  }

  protected findAccounts$(items: ExtendableEntityType[], options?: ExtendOptionsType): Observable<User[]> {
    const ids = this.pluckAccountsIds(items);
    if (!ids.length) {
      return of([]);
    }

    // загрузка данных порциями, затем соединение результатов в один массив

    const details = options?.account?.details || this.accountDetails || [];
    details.push('id');

    const idsChunks = chunk<string>(ids, 50);
    const observables: Array<Observable<User[]>> = idsChunks.map((_ids) => {
      const _filter: Partial<IFindAccountsFilter> = { id: _ids, use: FindAccountStrategyEnum.id };
      const pagination: IPagination = { page: 1, pageSize: _ids.length };
      return this.accountService
        .findAccounts(_filter, pagination, details)
        .pipe(map((response: IPagedResults<User[]>) => response.results));
    });
    return this.mergeChunks$<User>(observables);
  }

  protected findMeetings$(items: ExtendableEntityType[], options?: ExtendOptionsType): Observable<Meeting[]> {
    const ids = this.pluckMeetingsIds(items);
    if (!ids.length) {
      return of([]);
    }

    // загрузка данных порциями, затем соединение результатов в один массив

    const details = options?.meeting?.details || this.meetingDetails || [];
    details.push('id');

    const idsChunks = chunk<string>(ids, 50);
    const observables: Array<Observable<Meeting[]>> = idsChunks.map((_ids) => {
      const _filter: Partial<IFindMeetingsFilter> = { id: _ids };
      const pagination: IPagination = { page: 1, pageSize: _ids.length };
      return this.meetingsService
        .getMeetings(_filter, pagination, details)
        .pipe(map((response: IPagedResults<Meeting[]>) => response.results));
    });
    return this.mergeChunks$<Meeting>(observables);
  }

  protected findUserServices$(items: ExtendableEntityType[], options?: ExtendOptionsType): Observable<UserService[]> {
    const ids = this.pluckUserServiceIds(items);
    if (!ids.length) {
      return of([]);
    }

    // загрузка данных порциями, затем соединение результатов в один массив

    const details = options?.user_service?.details || this.userServiceDetails || [];
    details.push('id');

    const idsChunks = chunk<string>(ids, 50);
    const observables: Array<Observable<UserService[]>> = idsChunks.map((_ids) => {
      const _filter: Partial<IFindUserServicesFilter> = { id: _ids, use: FindUserServiceStrategyEnum.id };
      const pagination: IPagination = { page: 1, pageSize: _ids.length };
      return this.userServicesService
        .getServices(_filter, pagination, details)
        .pipe(map((response: IPagedResults<UserService[]>) => response.results));
    });
    return this.mergeChunks$<UserService>(observables);
  }

  protected findUserServiceContracts$(
    items: ExtendableEntityType[],
    options?: ExtendOptionsType,
  ): Observable<UserServiceContract[]> {
    const ids = this.pluckUserServiceContractsIds(items);
    if (!ids.length) {
      return of([]);
    }

    // загрузка данных порциями, затем соединение результатов в один массив

    const details = options?.user_service_contract?.details || this.userServiceContractDetails || [];
    details.push('id');

    const idsChunks = chunk<string>(ids, 50);
    const observables: Array<Observable<UserServiceContract[]>> = idsChunks.map((_ids) => {
      const _filter = new FindUserServiceContractsById({ id: _ids });
      const pagination: IPagination = { page: 1, pageSize: _ids.length };
      return this.userServiceContractsService
        .find(_filter, pagination, details)
        .pipe(map((response: IPagedResults<UserServiceContract[]>) => response.results));
    });
    return this.mergeChunks$<UserServiceContract>(observables);
  }

  protected pluckAccountsIds(items: ExtendableEntityType[]): string[] {
    return uniq(items.map((item) => item.account_id).filter((v) => !!v));
  }

  protected pluckUserServiceContractsIds(items: ExtendableEntityType[]): string[] {
    return uniq(items.map((item) => item.user_service_contract_id).filter((v) => !!v));
  }

  protected pluckUserServiceIds(items: ExtendableEntityType[]): string[] {
    return uniq<string>(items.map((item) => (item.user_service_contract_id ? null : item.user_service_id))).filter(
      (v) => !!v,
    );
  }

  protected pluckMeetingsIds(items: ExtendableEntityType[]): string[] {
    return uniq(items.map((item) => item.meeting_id).filter((v) => !!v));
  }

  protected mergeChunks$<T>(chunks$: Array<Observable<T[]>>): Observable<T[]> {
    return zip(...chunks$).pipe(map((chunks: T[][]) => chunks.reduce((arr, _chunk) => [...arr, ..._chunk], [] as T[])));
  }
}
