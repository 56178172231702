import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IContentCommentNotice, INoticeParams } from 'lingo2-models';
import { IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-content-like',
  templateUrl: './content-like.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLikeComponent implements OnInit {
  public params: IContentCommentNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params as IContentCommentNotice;
    this.base = {
      title: 'notifications.content_like.title',
      icon: 'notice-like',
      created_at: notification.created_at,
      modifier: null,
    };
  }

  public get user() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.user_url}'>${this.params.user_fullname}</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.params.content_url}'>${this.params.content_title}</a>`;
  }

  ngOnInit() {}
}
